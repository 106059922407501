<template>
	<div>
		<!-- 列表页内容区 -->
		<tableList ref="TableList" @buttonMethods="buttonMethods" @itemButtonMethods="itemButtonMethods" :sUrl="sUrl"></tableList>
		<!-- 作品列表 -->
		<el-dialog title="管理客户作品" :visible.sync="showWorkBox" width="55%"
			:close-on-click-modal="false" :close-on-press-escape="false" append-to-body center>
			<el-table :data="worksList" stripe style="width: 100%">
				<el-table-column prop="type" label="作品类型" align="center"></el-table-column>
				<el-table-column prop="content" label="作品内容" align="center" show-overflow-tooltip></el-table-column>
				<el-table-column prop="work_image_path" label="预览图" align="center" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-image style="width: 100px; height: 100px":src="scope.row.work_image_path" fit="fill" :preview-src-list="[scope.row.work_image_path]"></el-image>
					</template>
				</el-table-column>
				<el-table-column prop="step" label="作品状态" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.step == 0">
							<span class="tag" style="background-color:#00BFFF"></span>未提审
						</div>
						<div v-if="scope.row.step == 1">
							<span class="tag" style="background-color:#00CED1"></span>待审核
						</div>
						<div v-if="scope.row.step == 2">
							<span class="tag" style="background-color:#32CD32"></span>审核通过
						</div>
					</template>
				</el-table-column>
				<el-table-column  label="操作" width="160" align="center">
					<template slot-scope="scope">
						<template v-if="scope.row.type == '诗歌'">
							<el-button type="text" v-if="scope.row.step != 2" @click="audit(scope.row)">审核</el-button>
							<el-button type="text" v-if="scope.row.step == 2" @click="audit(scope.row)">重新审核</el-button>
							<el-button type="text" v-if="scope.row.step == 2" @click="audit2(scope.row)">下载</el-button>
						</template>
						<template v-if="scope.row.type == '书画'">
							<el-button type="text" v-if="scope.row.step != 2" @click="audit2(scope.row)">下载</el-button>
							<el-button type="text" v-if="scope.row.step == 2" @click="audit2(scope.row)">重新下载</el-button>
						</template>
					</template>
				</el-table-column>
			  </el-table>
		</el-dialog>
		<!-- 处理单个作品 -->
		<el-dialog
		  modal-append-to-body
		  :visible.sync="showWork"
		  width="30%"
		  center
		  fullscreen
		  >
		  <workDrawer :workInfo="workInfo" :activityId="activityId" :arrCustomer="arrCustomer" :worksList="worksList" @closeShowWork="closeShowWork"></workDrawer>
		</el-dialog>
		
		<!-- 批量处理作品 -->
		<el-dialog
		  modal-append-to-body
		  :visible.sync="showDealWork"
		  width="30%"
		  center
		  fullscreen
		  
		  >
		  <workDealDrawer @closeShowWork="closeShowWork" :actId="actId"></workDealDrawer>
		</el-dialog>
		
		<!-- 批量处理作品 -->
		<el-dialog
		  modal-append-to-body
		  :visible.sync="showDealFile"
		  width="30%"
		  center
		  fullscreen
		  >
		  <cropperImage :Name="cropperName" @uploadImgSuccess = "handleUploadSuccess" ref="child"></cropperImage>
		</el-dialog>
	</div>
</template>

<script>
	import oJs from "@/js/config.js";
	import oIndex from "@/js/customer/index.js";
	import tableList from '@/components/TableList.vue';
	import tableDrawer from '@/views/customer/index/edit.vue';
	import workDrawer from '@/views/customer/index/work.vue';
	import workDealDrawer from '@/views/customer/work/work.vue';
	import cropperImage from '@/components/CropperImage'
	export default {
		components: {
		    tableList,
			tableDrawer,
			workDrawer,
			workDealDrawer,
			cropperImage
		},
		data() {
			return {
				showDealFile:false, // 处理文件作品
				showWorkBox:false, // 展示作品弹窗
				showWork:false, // 展示作品
				sUrl : 'crm.works_deal', // 当前页面路径
				worksList: [], //作品列表
				workInfo: {}, // 作品详情
				activityId: '', // 活动ID
				nWorkId: '', // 作品ID
				arrCustomer:{}, // 客户信息
				showDealWork:false, // 展示批量处理作品页面
				cropperName:'',
				actId:this.$route.query.act_id
			};
		},
		created() {
		},
		watch:{
			showWorkBox(newValue, oldValue){
				if (newValue == false) {
					this.referList()
				}
			}
		},
		
		methods: {
			/**
			 * 顶部按钮点击事件
			 * @param {Object} action 触发方法
			 * @param {Object} param 携带参数
			 */
			buttonMethods(action, param) {
				console.log('触发方法：'+action)
				this[action](param)
			},
			/**
			 * 行内按钮点击事件
			 * @param {Object} action 触发方法
			 * @param {Object} param 携带参数
			 */
			itemButtonMethods(action, param){
				console.log('触发方法：'+action)
				this[action](param)
			},
			
			/**
			 * 刷新列表
			 */
			referList(){
				this.$refs.TableList.getListApi();
			},
			
			/**
			 * 打开作品管理抽屉
			 */
			getWork(param){
				this.arrCustomer = param;
				this.nWorkId = param.id;
				this.getWorkList()
			},
			// 获取作品列表
			getWorkList(){
				let data = {
					id : this.nWorkId
				}
				oIndex.getEditInfo(data).then((res) => {
					this.worksList = res.work
					this.activityId = res.info.activity_id
					this.showWorkBox = true;
				})
			},
			/**
			 * 审核/作图
			 * @param {Object} param
			 */
			audit(param){
				console.log(param)
				this.workInfo = param
				this.showWork = true;
			},
			// 关闭作品弹窗时触发
			closeShowWork(){
				this.getWorkList();
				this.showWork = false;
			},
			// 处理客户作品按钮
			dealWorks(){
				this.showDealWork = true;
			},
			// 处理客户作品按钮
			dealFiles(){
				this.showDealFile = true;
			},
			// 同步数据
			syncWorks(){
				oJs.request('admin/crm.works_deal/syncWorks?act_id='+this.actId).then(res => {
					
				})
			},
			// 上传成功
			handleUploadSuccess(){
				
			},
			// 审核书画作品
			audit2(param){
				oJs.request('admin/crm.works/audit', param).then(res => {
					this.downloadIamge(param.work_image_path, this.arrCustomer.name, param)
					this.getWorkList()
				})
				
			},
			downloadIamge(imgsrc, name, param) {
				const src = `${imgsrc}?t=${new Date().getTime()}`
				  fetch(src).then(res => {
				    res.blob().then(myBlob => {
				      const href = URL.createObjectURL(myBlob)
				      const a = document.createElement('a')
				      a.href = href
				      a.download = name
				      a.click()
				      a.remove()
				    })
				  })     
				
				// //下载图片地址和图片名
				// var image = new Image();
				// // 解决跨域 Canvas 污染问题
				// image.setAttribute("crossOrigin", "anonymous");
				// image.onload = function() {
				// 	var canvas = document.createElement("canvas");
				// 	canvas.width = image.width;
				// 	canvas.height = image.height;
				// 	var context = canvas.getContext("2d");
				// 	context.drawImage(image, 0, 0, image.width, image.height);
				// 	var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

				// 	var a = document.createElement("a"); // 生成一个a元素
				// 	var event = new MouseEvent("click"); // 创建一个单击事件
				// 	a.download = name || "photo"; // 设置图片名称
				// 	a.href = url; // 将生成的URL设置为a.href属性
				// 	a.dispatchEvent(event); // 触发a的单击事件
				// };
				// image.src = imgsrc;
			},
		},
	};
</script>

<style lang="less" scoped>
	/deep/ .el-drawer__body{
		overflow:visible !important;
	}
	/deep/ .el-drawer{
		overflow:visible !important;
	}
	.el-breadcrumb {
		margin-bottom: 15px;
		// font-size: 12px;
	}


	.icon-exchangerate {
		font-size: 30px;
		float: right;
		text-align: center;
	}

	.icon-exchangerate:hover {
		font-size: 32px;
		color: #00FFFF;
	}

	.tag {
		position: relative;
		top: -1px;
		display: inline-block;
		width: 6px;
		height: 6px;
		vertical-align: middle;
		border-radius: 50%;
		margin-right: 6px;
	}
</style>
