<template>
	<div class="cropper-content">
		<div class="cropper-box">
			<!--底部操作工具按钮-->
			<div class="footer-btn">
				<div class="scope-btn">
					<label class="btn" for="uploads">选择封面</label>
					<input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);"
						accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)">
					<el-button size="mini" type="danger" plain icon="el-icon-zoom-in" @click="changeScale(1)">放大
					</el-button>
					<el-button size="mini" type="danger" plain icon="el-icon-zoom-out" @click="changeScale(-1)">缩小
					</el-button>
					<el-button size="mini" type="danger" plain @click="rotateLeft">↺ 左旋转</el-button>
					<el-button size="mini" type="danger" plain @click="rotateRight">↻ 右旋转</el-button>
		 	</div>
				<div class="upload-btn">
					
					
					<el-button size="mini" type="danger" plain icon="el-icon-zoom-in" @click="changeSize(1)">增加大小
					</el-button>
					<el-button size="mini" type="danger" plain icon="el-icon-zoom-out" @click="changeSize(-1)">缩小大小
					</el-button>
					
					
					
					
					<el-button size="mini" type="success" @click="uploadImg('blob')">上传封面 <i class="el-icon-upload"></i>
					</el-button>
				</div>
			</div>
			当前原始图片大小：{{nOriginalFileSize}} kb
			当前截取图片大小：{{nScreenshotFileSize}} kb
			<div class="cropper">
				<vue-cropper ref="cropper" :img="option.img" :outputSize="option.outputSize"
					:outputType="option.outputType" :info="option.info" :canScale="option.canScale"
					:autoCrop="option.autoCrop" :autoCropWidth="option.autoCropWidth"
					:autoCropHeight="option.autoCropHeight" :fixed="option.fixed" :fixedNumber="option.fixedNumber"
					:full="option.full" :fixedBox="option.fixedBox" :canMove="option.canMove"
					:canMoveBox="option.canMoveBox" :original="option.original" :centerBox="option.centerBox"
					:height="option.height" :infoTrue="option.infoTrue" :maxImgSize="option.maxImgSize"
					:enlarge="option.enlarge" :mode="option.mode" @realTime="realTime" @imgLoad="imgLoad">
				</vue-cropper>
			</div>

		</div>
		<!--预览效果图-->
		预览图
		<div class="show-preview">
			<div :style="previews.div" class="preview">
				<img :src="previews.url" :style="previews.img">
			</div>
		</div>
	</div>
</template>
<script>
	import oJs from '@/js/config.js';
	import {
		VueCropper
	} from 'vue-cropper'
	export default {
		name: "CropperImage",
		components: {
			VueCropper
		},
		props: [
			'Name',
			'sImgUrl'
			],
		data() {
			return {
				name: this.Name,
				// sImgUrl:this.sImgUrl,
				previews: {},
				option: {
					img: '', //裁剪图片的地址
					// outputSize: 0.875, //裁剪生成图片的质量(可选0.1 - 1)
					outputSize: 0.1, //裁剪生成图片的质量(可选0.1 - 1)
					outputType: 'png', //裁剪生成图片的格式（jpeg || png || webp）
					info: true, //图片大小信息
					canScale: true, //图片是否允许滚轮缩放
					autoCrop: true, //是否默认生成截图框
					autoCropWidth: 300, //默认生成截图框宽度
					autoCropHeight: 300, //默认生成截图框高度
					fixed: false, //是否开启截图框宽高固定比例
					fixedNumber: [100, 100], //截图框的宽高比例
					full: false, //false按原比例裁切图片，不失真
					fixedBox: false, //固定截图框大小，不允许改变
					canMove: true, //上传图片是否可以移动
					canMoveBox: false, //截图框能否拖动
					original: true, //上传图片按照原始比例渲染
					centerBox: true, //截图框是否被限制在图片里面
					height: false, //是否按照设备的dpr 输出等比例图片
					infoTrue: false, //true为展示真实输出图片宽高，false展示看到的截图框宽高
					maxImgSize: 3000, //限制图片最大宽度和高度
					enlarge: 1, //图片根据截图框输出比例倍数
					// 好像没有   你展示那么大，没意义呀    起码得原比例展示   这样？  可以
 					mode: 'contain' ,//图片默认渲染方式
				},
				nScreenshotFileSize:0, //  截取图片大小
				nOriginalFileSize:0, // 当前原图大小
			};
		},
		methods: {
			changeSize(val){
				if (val == 1) {
					this.option.outputSize += 0.1
				}else{
					this.option.outputSize -= 0.1
				}
			},
			//初始化函数
			imgLoad(msg) {
				console.log("工具初始化函数=====" + msg)
			},
			//图片缩放
			changeScale(num) {
				num = num || 1
				this.$refs.cropper.changeScale(num)
			},
			//向左旋转
			rotateLeft() {
				this.$refs.cropper.rotateLeft()
			},
			//向右旋转
			rotateRight() {
				this.$refs.cropper.rotateRight()
			},
			//实时预览函数
			// 下面预览图的方法  同用 data渲染的  我想让他用 this.$refs.cropper.getCropBlob(async (data) 里的data  这个是提交的
			
			realTime(data) {
				this.previews = data
				console.log(data)
				this.$refs.cropper.getCropBlob(async (data) => {
					this.nScreenshotFileSize = data.size
					this.$forceUpdate();
					//  算了  卡一下问题不大  现在最大的问题是 让他展示全了  能不能改他原来的代码 让宽高都是 100%  哪个地方展示全？
					console.log(data);
				})
			},
			//选择图片
			// 选择图片那个按钮方法  现在他用 file渲染的  我想用 url
			selectImg(e) {
				oJs.request('api/test/blob').then(res => {
					this.option.img = res;
				})
			},

			// 最后提交的方法  这个提交的data  后台直接就受到了
			
			//  高度不够  能自适应吗
			
			
			
			
			
			//  OK了
			uploadImg(type) {
				let _this = this;
				if (type === 'blob') {
					//获取截图的blob数据
					this.$refs.cropper.getCropBlob(async (data) => {
						let formData = new FormData();
						formData.append('file', data, "DX.jpg")
						console.log(formData);
						//调用axios上传
						oJs.request('admin/crm.works/uploadFile', formData).then(res => {
							console.log(res)
						})
					})
				}
			},
		},
	}
</script>

<style scoped lang="less">
	.cropper-content {

		// display: flex;
		// display: -webkit-flex;
		// justify-content: flex-end;
		.cropper-box {
			// flex: 1;
			width: 100%;

			.cropper {
				width: 100%;
				height: 1000px;
			}
		}

		.show-preview {
			margin-top: 20px;
			// flex: 1;
			// -webkit-flex: 1;
			// display: flex;
			// display: -webkit-flex;
			// justify-content: center;
			.preview {
				overflow: hidden;
				border: 1px solid #67c23a;
				background: #cccccc;
			}
		}
	}

	.footer-btn {
		margin-bottom: 30px;
		display: flex;
		display: -webkit-flex;
		justify-content: flex-end;

		.scope-btn {
			display: flex;
			display: -webkit-flex;
			justify-content: space-between;
			padding-right: 10px;
		}

		.upload-btn {
			flex: 1;
			-webkit-flex: 1;
			display: flex;
			display: -webkit-flex;
			justify-content: center;
		}

		.btn {
			outline: none;
			display: inline-block;
			line-height: 1;
			white-space: nowrap;
			cursor: pointer;
			-webkit-appearance: none;
			text-align: center;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			outline: 0;
			-webkit-transition: .1s;
			transition: .1s;
			font-weight: 500;
			padding: 8px 15px;
			font-size: 12px;
			border-radius: 3px;
			color: #fff;
			background-color: #409EFF;
			border-color: #409EFF;
			margin-right: 10px;
		}
	}
</style>
